<template>
  <div>
    <div class="bar-box">
      <div class="search-input"><i class="el-icon-search"></i>
        <el-input v-model="searchWords" @input="handleSearch" placeholder="搜索书签"></el-input>
        <i class="el-icon-circle-close" v-if="searchWords" @click="searchWords = ''"></i></div>
    </div>

    <fixed-menu :data="fixedItems" @item-click="handleItemClick"></fixed-menu>

    <div class="list-box">
      <div class="fixed-button">
        <div>
          <div>
            <el-button size="small" @click="handleAdd('folder')" draggable="true">添加文件夹</el-button>
          </div>
          <div>
            <el-button size="small" draggable="true" @click="handleAdd('url')">添加网址</el-button>
          </div>
          <div>
            <el-button size="small" draggable="true" @click="handleAdd('note')">添加笔记</el-button>
          </div>
          <div class="drag-box" @drop.prevent="handleAddDrop" @dragover.prevent>
            <div>快捷添加</div>
            <div>支持拖放</div>
            <div>网址</div>
            <div>文本</div>
          </div>
        </div>
      </div>

      <el-tree ref="tree" :data="(searchWords ? searchItems : treeItems)" :draggable="searchWords == ''"
               :allow-drop="allowDrop"
               :props="{label:'title'}"
               node-key="id"
               @node-drop="handleMove">
        <div class="note-slot" slot-scope="{ node, data }">

          <div class="custom-tree-node">
            <div v-if="data.type === 'folder'" @click="handleItemClick(data)">
              <i class="el-icon-folder"></i>
              {{ data.title }}
            </div>

            <div v-if="data.type === 'url'" @click="handleItemClick(data)" class="note-url">
              <img class="site-ico" :src="siteIco(data.url)" @error="siteIcoError"/>
              {{ data.title }}
              <span class="url">{{ decodeURI(data.url) }}</span>
            </div>

            <div v-if="data.type === 'note'" @click="handleItemClick(data)">
              <i class="el-icon-notebook-2"></i>
              {{ data.title }}
            </div>

            <div @click.stop="">
              <el-dropdown>
                <i class="el-icon-more" style="margin:0 6px;"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <div @click="handleFixed(data)">{{ parseInt(data.fixed) ? '取消置顶' : '置顶' }}</div>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="data.type === 'folder'">
                    <div @click="handleAdd('folder', data.id)">添加文件夹</div>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="data.type === 'folder'">
                    <div @click="handleAdd('url', data.id)">添加网址</div>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="data.type === 'folder'">
                    <div @click="handleAdd('note', data.id)">添加笔记</div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="handleEdit(data)">编辑</div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="handleDelete(data)">删除</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>

        </div>
      </el-tree>
    </div>

    <el-dialog
        :title="editForm.id ? '编辑' : '添加'"
        width="30%"
        :visible="editFormShow"
        :before-close="handleEditFormClose">
      <el-input v-model="editForm.title" placeholder="请输入标题" style="margin-bottom: 12px"></el-input>
      <el-input v-model="editForm.url" placeholder="请输入网址" v-if="editForm.type == 'url'"
                style="margin-bottom: 12px"></el-input>
      <el-input v-model="editForm.content" type="textarea" placeholder="请输入内容" v-if="editForm.type == 'note'"
                style="margin-bottom: 12px;" :rows="20"></el-input>
      <div style="margin-bottom: 12px">
        <el-cascader
            v-model="editForm.pid"
            :props="{ expandTrigger: 'hover', checkStrictly : true, label: 'title' }"
            :options="editFormFolders" placeholder="请选择文件夹"></el-cascader>
      </div>
      <el-button type="primary" @click="handleSave">保存</el-button>
    </el-dialog>

    <el-dialog
        :title="contentData.title"
        width="30%"
        :visible="true"
        v-if="contentShow"
        :before-close="handleContentShowClose">
      <div class="note-content-box">{{ contentData.content }}</div>
    </el-dialog>

    <el-dialog
        title="登录"
        width="30%"
        :visible="loginShow"
        :before-close="handleLoginClose">
      <el-input v-model="loginForm.username" placeholder="请输入用户名" style="margin-bottom: 12px"></el-input>
      <el-input v-model="loginForm.password" placeholder="请输入密码" type="password" style="margin-bottom: 12px"></el-input>
      <el-button type="primary" @click="handleLogin">登录</el-button>
    </el-dialog>
  </div>
</template>

<script>
import {apiPost} from '@/utils/api.js'
import icoDefault from '@/assets/ico-default.png'
import FixedMenu from "./component/FixedMenu";

export default {
  name: "bookmarks",
  components: {FixedMenu},
  data() {
    return {
      searchWords: '', // 搜索词
      searchItems: [], // 搜索结果
      all: [], // 全部数据
      treeItems: [], // 格式化后的数据
      fixedItems: [], // 格式化后的置顶数据

      editForm: {
        id: "",
        pid: 0,
        type: "",
        title: "",
        content: "",
        url: "",
      },
      editFormFolders: [],
      editFormShow: false,

      contentData: null,
      contentShow: false,

      loginForm: {
        username: "",
        password: ""
      },
      loginShow: false,
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    siteIco(url) {
      return url.replace(/(?<!:|:\/)\/.*$/, '/favicon.ico')
    },

    siteIcoError(el) {
      el.target.src = icoDefault
    },

    allowDrop(draggingNode, dropNode, type) {
      return dropNode.data.type === 'folder' || type != 'inner';
    },

    loadData() {
      apiPost('/mark/home').then(result => {
        console.log(result)
        if (result.code == 200) {
          this.all = result.data.all || []
          this.treeItems = this.formatData(result.data.all, '0') || []
          this.fixedItems = this.fillList(result.data.fixed) || []
        }
      }).catch(e => {
        if (e.code && e.code == 401) {
          this.loginShow = true
        }
      })
    },

    fillList(data) {
      return data.map(v => {
        v.children = this.formatData(this.all, v.id)
        return v
      })
    },

    formatData(data, pid) {
      let list = data.filter(v => v.pid == pid)

      return list.map(v => {
        v.children = this.formatData(data, v.id)
        return v
      })
    },

    formatFolder(data, pid) {
      let list = data.filter(v => v.pid == pid && v.type === 'folder')

      return list.map(v => {
        const item = {
          value: v.id,
          title: v.title,
          children: this.formatFolder(data, v.id),
        }

        if (item.children.length == 0) {
          item.children = undefined
        }
        return item
      })
    },

    deleteItem(items, id) {
      for (let i in items) {
        let v = items[i]

        if (v.id == id) {
          items.splice(i, 1)
          return true
        }

        if (v.children.length) {
          if (this.deleteItem(v.children, id)) {
            return true
          }
        }
      }
    },

    updateItem(items, data) {
      for (let i in items) {
        let v = items[i]

        if (v.id == data.id) {
          items.splice(i, 1, Object.assign(v, data))
          return true
        }

        if (v.children && v.children.length) {
          if (this.updateItem(v.children, data)) {
            return true
          }
        }
      }
    },

    handleMenuClick(key) {
      let item = this.all.filter(v => v.id == key)[0] || null
      if (item) {
        this.handleItemClick(item)
      }
    },

    handleSearch() {
      if (this.searchWords) {
        const list = this.all.filter(v => v.title.indexOf(this.searchWords) >= 0 || v.url.indexOf(this.searchWords) >= 0)

        this.searchItems = this.fillList(list)
      }
    },

    handleItemClick(data) {
      console.log('handleItemClick')
      console.log(data)
      if (data.type == 'note') {
        this.handleContentShow(data)
      }
      if (data.type == 'url') {
        window.open(data.url, '_blank')
      }
    },

    handleMove(node, target, position) {
      let orders = []

      if (position == 'inner') {
        target.data.children.map(v => {
          orders.push([v.id, target.data.id])
        })
      } else {
        if (parseInt(target.data.pid)) {
          this.$refs.tree.getNode(target.data.pid).data.children.map(v => {
            orders.push([v.id, target.data.pid])
          })
        } else {
          orders = this.$refs.tree.data.map(v => {
            orders.push([v.id, 0])
          })
        }
      }

      apiPost('/mark/orders', {orders})
    },

    handleAddDrop(e) {
      let url = e.dataTransfer.getData("Url")
      if (url) {
        apiPost('/mark/title', {url}).then(result => {
          if (result.code == 200) {
            this.editForm = {
              id: 0,
              pid: 0,
              type: 'url',
              title: result.data,
              content: "",
              url: url,
            }
            this.editFormShow = true
          }
        })
        return
      }

      let text = e.dataTransfer.getData("text")
      if (text) {
        this.editForm = {
          id: 0,
          pid: 0,
          type: 'note',
          title: text,
          content: "",
          url: url,
        }
        this.editFormShow = true

        return
      }
    },

    handleFixed(data) {
      apiPost('/mark/fixed', {id: data.id, type: parseInt(data.fixed) ? 0 : 1}).then(result => {
        if (result.code == 200) {
          if (result.data) {
            data.fixed = result.data
            this.fixedItems.push(data)
          } else {
            for (const i in this.fixedItems) {
              if (this.fixedItems[i].id == data.id) {
                this.fixedItems.splice(i, 1)
                break
              }
            }
          }
        }
      })
    },

    handleAdd(addType, pid) {
      this.editForm = {
        id: "",
        pid: pid || 0,
        type: addType,
        title: "",
        content: "",
        url: "",
      }

      this.editFormFolders = this.formatFolder(this.all, 0)

      this.editFormShow = true
    },

    async handleEdit(item) {
      this.editForm = {
        id: item.id,
        pid: item.pid,
        type: item.type,
        title: item.title,
        url: item.url,
        content: "",
      }

      this.editFormFolders = this.formatFolder(this.all, 0)

      if (item.type === 'note') {
        let res = await apiPost('/mark/content', {id: item.id})
        if (res.code === 0) {
          this.editForm.content = res.data
        } else {
          return
        }
      }

      this.editFormShow = true
    },

    handleSave() {
      if (typeof this.editForm.pid == 'object') {
        this.editForm.pid = this.editForm.pid.pop()
      }

      apiPost('/mark/save', this.editForm).then(result => {
        if (result.code == 200) {
          if (this.editForm.id == 0) {
            // 插入文档树
            if (parseInt(result.data.pid)) {
              this.$refs.tree.append(result.data, this.$refs.tree.getNode(result.data.pid))
            } else {
              this.$refs.tree.append(result.data)
            }

            // 补充到所有数据
            this.all.push(result.data)
          } else {
            // 更新数据
            this.updateItem(this.all, result.data)
            this.updateItem(this.fixedItems, result.data)
            this.updateItem(this.treeItems, result.data)
          }

          this.handleEditFormClose()
        }
      })
    },

    handleLogin() {
      if (this.loginForm.username.trim() == '') {
        this.$message.warning('请输入用户名')
        return
      }
      if (this.loginForm.password.trim() == '') {
        this.$message.warning('请输入密码')
        return
      }

      console.log(this.loginForm)
      apiPost('/auth/login', this.loginForm).then(result => {
        console.log(result)
        console.log(result.code)
        if (result.code === 0) {
          this.loadData()
          this.loginShow = false
        }
      })

    },

    handleDelete(data) {
      apiPost('/mark/delete', {id: data.id}).then(result => {
        if (result.code == 200) {
          this.deleteItem(this.all, data.id)
          this.deleteItem(this.fixedItems, data.id)
          this.$refs.tree.remove(data)

          this.handleEditFormClose()
        }
      })
    },

    handleEditFormClose() {
      this.editFormShow = false
    },

    async handleContentShow(data) {
      let res = await apiPost('/mark/content', {id: data.id})
      if (res.code === 0) {
        data.content = res.data

        this.contentData = data
        this.contentShow = true
      } else {
        return
      }
    },

    handleContentShowClose() {
      this.contentShow = false
    },
    handleLoginClose() {
      this.loginShow = false
    },
  }

}
</script>

<style>
html, body {
  background-color: #F8F9FA;
  height: 100%;
  margin: 0;
}

.note-slot {
  flex: 1;
  overflow: hidden;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  width: 100%;
  box-sizing: border-box;
}

.custom-tree-node > :first-child {
  flex: 1;
}

.custom-tree-node .note-url {
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-tree-node .url {
  display: none;
  color: #9DA3AC;
}

.custom-tree-node:hover .url {
  display: inline;
}

.list-box {
  padding-top: 24px;
}

.list-box .el-tree {
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, .3) 0 1px 2px 0, rgba(60, 64, 67, .15) 0 1px 3px 1px;
  margin: 0 auto;
  max-width: 960px;
  padding: 8px 0;
}

.list-box .el-tree-node__content {
  line-height: 40px;
  height: 40px;
  color: rgb(32, 33, 36);
}

.list-box .fixed-button {
  margin: 0 auto;
  max-width: 960px;
  position: relative;
}

.list-box .fixed-button > div {
  position: absolute;
  left: calc(100% + 12px);
  top: 0;
}

.list-box .fixed-button > div > div {
  margin-bottom: 6px;
}

.bar-box {
  align-items: center;
  background-color: rgb(51, 103, 214);
  color: #fff;
  display: flex;
  height: 56px;
}

.menu-box {
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, .3) 0 1px 2px 0, rgba(60, 64, 67, .15) 0 1px 3px 1px;
  margin: 24px auto 0;
  max-width: 960px;
  padding: 8px 0;
}

.menu-box .el-menu-item, .menu-box .el-submenu, .menu-box .el-submenu__title {
  height: 21px !important;
  line-height: 21px !important;
}

.menu-box .el-menu.el-menu--horizontal {
  display: inline-block;
  border-bottom: none;
}

.menu-box .el-menu--horizontal > .el-submenu.is-active .el-submenu__title, .menu-box .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}

.search-input {
  background: rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  cursor: text;
  padding-inline-end: 0;
  width: 680px;
  align-items: center;
  display: flex;
  height: 40px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0.4, 0, 0.2, 1);
  margin: 0 auto;
}

.search-input .el-input__inner {
  background: rgba(0, 0, 0, 0);
  border: none;
  color: #fff;
  padding: 0 5px;
}


.search-input .el-icon-search {
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin: 10px;
  cursor: pointer;
}

.search-input .el-icon-circle-close {
  font-size: 20px;
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
}

.site-ico {
  height: 14px;
  float: left;
  margin: 13px 6px 0 0
}

.drag-box {
  border-radius: 3px;
  border: 1px solid #DCDFE6;
  background: #FFF;
  text-align: center;
  line-height: 2;
  color: #909399;
  padding: 60px 12px;
}

.note-content-box {
  white-space: pre;
  line-height: 1.5em;
  background-color: #F8F8F8;
  color: #525252;
  padding: 20px;
  overflow: auto;
  max-height: 800px;
}
</style>
